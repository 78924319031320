function App() {
  const nodes = [
    {
      name: "Celo",
      url: "https://celo.quickestnode.com ",
    },
  ];
  return (
    <div style={{ marginLeft: "8px", maxWidth: "800px" }}>
      <h1>QuickestNode</h1>
      <p>QuickestNode hosts free, public RPCs for select blockchains:</p>
      <ul>
        {nodes.map(({ name, url }, idx) => (
          <li key={idx} target="_blank">
            {name}: <a href={url}>{url}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;
